import Vue from "vue";
import VueRouter from "vue-router";
import PublicRoute from "./public";
import AuthRoute from "./auth";
Vue.use(VueRouter);

const routes = [...PublicRoute, AuthRoute];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("access_token");
  if (token && (to.name == "login" || to.name == "Register")) {
    next("/auth/time-entry");
  }
  if (to.meta.requiresAuth) {
    if (token) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next("/");
    }
  } else {
    // Non-protected route, allow access
    next();
  }
});
export default router;
