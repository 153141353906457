// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  invoices: [],
  totalPage: 0,
  count: 0
};

const getters = {
};

const actions = {
    getAllInvoices(context, data) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/invoice/all?current_page=${data?.currentPage}&page_size=${data?.pageSize}`, {

            headers: {
              ...authHeaders(localStorage.getItem('access_token'))
            },
          })
          .then((response) => {

            if(response){

              context.commit('set_invoices', response.data.data)
              context.commit('set_totalPage', response.data.totalPage)
              resolve(response);
            }

          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getSearchInvoices(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/invoice/all?search=${data}`, {
            headers: {
              ...authHeaders(localStorage.getItem('access_token'))
            },
          })
          .then((response) => {
            context.commit('set_invoices', response.data.data)
            context.commit('set_count', response.data.count)
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getInvoiceResources(context, matterId) {
      return new Promise((resolve, reject) => {
      axios
          .get(`/invoice/resources/${matterId}`, {
          headers: {
              ...authHeaders(localStorage.getItem('access_token'))
          },
          })
          .then((response) => {
              resolve(response);
          })
          .catch((error) => {
              reject(error);
          });
      });
    },
    createInvoice(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/invoice/", data, {
            headers: {
              ...authHeaders(localStorage.getItem('access_token'))
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    markedPaidInvoice(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/invoice/mark_paid/${data.id}`, data.paidStatus, {
            headers: {
              ...authHeaders(localStorage.getItem('access_token'))
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    cancelInvoice(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/invoice/mark_cancelled/${data.id}`, data.cancelledStatus, {
            headers: {
              ...authHeaders(localStorage.getItem('access_token'))
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

 
};

const mutations = {
  add_invoice_obj(state, data) {
    state.entries.push(data)
  },
  set_invoices(state, data) {
    state.invoices = data
  },
  set_totalPage(state, data) {
    state.totalPage = data
  },
  set_count(state, data) {
    state.count = data
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
