// import router from '@/router';
// import Auth from '@/store/auth'
// import { authHeaders } from "@/store/config";

const state = {
    phases: []
};

const getters = {
    user: state => state.phases,
};

const actions = {
    //
};

const mutations = {
    //
};

export default {
    state, getters, actions, mutations, namespaced: true,
}