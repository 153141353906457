<template>
    <div>
        <v-app-bar
        class="nav_bar"
        color="#fff"
        dense
        outlined
        light
        elevation="0"
        >
            <!-- <span class="ms-6 page_title">Dashboard</span> -->
            <v-spacer></v-spacer>
            <span class="me-2 nav_text">{{ userDetails.first_name }} {{ userDetails.last_name }}</span>
            <v-avatar rounded size="30" color="#FFCECE" class="me-2">
                <v-icon size="20" color="#FF9292" dark>
                    mdi-account
                </v-icon>
            </v-avatar>
            <v-btn
              icon
              small
              class="me-4"
            >
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
    </div>
</template>
  
<script>

    export default {
        name: 'LoginPage',
        components: {
        },
        data: () => ({
            show: false,
            commentDrawer: false,
            comments: 2,
            email: '',
            password: '',
            items: [
                { title: 'Home', icon: 'mdi-home-city' },
                { title: 'My Account', icon: 'mdi-account' },
                { title: 'Users', icon: 'mdi-account-group-outline' },
            ],
        }),
        methods: {
        },
        computed: {
            userDetails() {
                return this.$store.state.Auth.user;
            },
        }
    }
</script>

<style scoped>
    .logo_banner {
        width: 11rem;
    }
    .page_title {
        font-size: 1rem;
        font-weight: 600;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .comment_title {
        font-size: 14px;
        font-weight: 400;
    }
    .comment_date {
        font-size: 13px;
        color: #666666;
    }
    .comment_text {
        font-size: 14px;
        font-weight: 400;
    }
    .nav_text {
        font-size: 12px;
    }
    
</style>