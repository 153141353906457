// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  profitCenters: [],
};

const getters = {
  user: (state) => state.clients,
};

const actions = {
  createProfitCenter(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .post("/profit-center/", data, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          context.commit('add_profitCenter_obj', response.data.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateProfitCenter(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .put(`/profit-center/${data.id}`, data.profitCenter, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteProfitCenter(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/profit-center/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllProfitCenter(context ) {
    return new Promise((resolve, reject) => {
      axios
        .get("/profit-center/all", {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response){

            context.commit('set_profitCenters', response.data.data)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_profitCenter_obj(state, data) {
    state.profitCenters.push(data)
  },
  set_profitCenters(state, data) {
      state.profitCenters = data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
