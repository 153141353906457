// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";
import EventBus from "@/common/EventBus";

const state = {
  departments: [],
};

const getters = {

};

const actions = {
  createDepartment(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .post("/department/", data, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          context.commit('add_department_obj', response.data.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateDepartment(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .put(`/department/${data.id}`, data.department, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteDepartment(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .delete(`/department/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response.data){
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAllDepartment(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/department/all", {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response){
            context.commit('set_department', response.data.data)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_department_obj(state, data) {
    state.departments.push(data)
  },
  set_department(state, data) {
    state.departments = data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
