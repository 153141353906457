import router from '@/router';
import axios from 'axios'
import { defaultHeaders } from "@/store/config";

const state = {
    token: localStorage.getItem('access_token') || null,
    authStatus: false,
    verifyEmail: '',
    resetToken: localStorage.getItem('refresh_token') || null,
    user: {},
    currencies: []
};

const getters = {
  user: state => state.user,
};

const actions = {
    login(context, data) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        axios.post('/org/login', data, 
        {
          headers: defaultHeaders,
        })
        .then(response => {
          if (response && response.status == 200) {
            const token = response.data.data.token;
            const refreshToken = response.data.data.refreshToken;
            context.commit('set_user', response.data.data.user)
            localStorage.setItem('access_token', token);
            localStorage.setItem('refresh_token', refreshToken);
            context.commit('set_token', token);
            context.commit('set_refreshToken', token);
            router.push({
              name:'Loading'
            })
          }
          let countryCurrency = [
            {
              "symbol": "$",
              "name": "US Dollar",
              "symbol_native": "$",
              "code": "USD",
              "name_plural": "US dollars"
            }
          ]
          context.commit('set_currencies', countryCurrency);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  register(context, data) {
    
    return new Promise((resolve, reject) => {
      axios.post('/org/new', data,
        {
          headers: defaultHeaders,
        })
        .then(response => {
          
          const verifyEmail = response.data.data.organisation.email;
          context.commit('set_verifyEmail', verifyEmail)
          router.push({
            name: 'Verification',
            params: {
              email: data.email
            }
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  resendCode(context, data) {
    return new Promise((resolve, reject) => {
      axios.post('/org/resend-verification-account', data,
        {
          headers: defaultHeaders,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  verifyAccount(context, data) {
    
    return new Promise((resolve, reject) => {
      axios.post('/org/verify-account', {
        email: context.state.verifyEmail,
        code: data.code,
      },
        {
          headers: defaultHeaders,
        })
        .then(response => {
          
          router.push({
            name: 'Verified'
          })
          resolve(response)
        })
        .catch(error => {
          
          reject(error)
        })
    })
  },

  forgotPassword(context, data) {
    
    return new Promise((resolve, reject) => {
      axios.post('/org/request-password-reset', data,
        {
          headers: defaultHeaders,
        })
        .then(response => {
          router.push({
            name: 'ResetMail'
          })
          resolve(response)
        })
        .catch(error => {
          
          reject(error)
        })
    })
  },

  resetPassword(context, data) {
    
    return new Promise((resolve, reject) => {
      axios.post(`/org/reset-password/${data.token}`, {
        newPassword: data.password,
        confirmPassword: data.confirmPassword,
      },
        {
          headers: defaultHeaders,
        })
        .then(response => {
          
          router.push({
            name: 'login'
          })
          resolve(response)
        })
        .catch(error => {
          
          reject(error)
        })
    })
  },

  createPassword(context, data) {
    
    return new Promise((resolve, reject) => {
      axios.post(`/org/create-password`, data,
        {
          headers: defaultHeaders,
        })
        .then(response => {
          
          router.push({
            name: 'login'
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  setupWizardChange(context, data) {
    context.commit('set_setupWizard', data);
  },

  logout(){
    localStorage.removeItem('vuex')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem("timerRunning");
    localStorage.removeItem("timerStartTime");
    localStorage.removeItem("timerPauseTime");
    router.push('/')
  }

};

const mutations = {
  set_user(state, data) {
    state.user = data
  },
  set_verifyEmail(state, data) {
    state.verifyEmail = data
  },
  set_token(state, data) {
    state.token = data
  },
  set_refreshToken(state, data) {
    state.resetToken = data
  },
  set_currencies(state, data) {
    state.currencies = data
  },
  set_setupWizard(state, data) {
    console.log('setup called')
    state.user.org.setup_wizard_status = data
  }
};

export default {
  state, getters, actions, mutations, namespaced: true,
}