// import router from '@/router';
import axios from 'axios'
import Auth from '@/store/auth'
import { authHeaders } from "@/store/config";

const state = {
    tasks: []
};

const getters = {
    user: state => state.tasks,
};

const actions = {
    createTask(context, data) {
        
        return new Promise((resolve, reject) => {
            axios.post('/task/', data, 
            {
              headers: {
                  ...authHeaders(Auth.state.token)
              },
            })
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
    },
};

const mutations = {
    //
};

export default {
    state, getters, actions, mutations, namespaced: true,
}