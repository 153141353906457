import Login from '../views/Login.vue'
export default [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/verification',
        name: 'Verification',
        query:{},
        component: () => import('@/views/Verification.vue')
    },
    {
        path: '/verified',
        name: 'Verified',
        component: () => import('@/views/Verified.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPwd',
        component: () => import('@/views/ForgotPwd.vue')
    },
    {
        path: '/org/reset-password',
        name: 'ResetPwd',
        query:{},
        component: () => import('@/views/ResetPwd.vue')
    },
    {
        path: '/org/create-password',
        name: 'CreatePassword',
        query:{},
        component: () => import('@/views/CreatePassword.vue')
    },
    {
        path: '/reset-mail',
        name: 'ResetMail',
        component: () => import('@/views/ResetMail.vue')
    }
]