// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";
import EventBus from "@/common/EventBus";

const state = {
  clients: [],
};

const getters = {
  
};

const actions = {
  createClient(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .post("/client", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit('add_client_obj', response.data.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getClients(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/client/all", {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if(response){

            context.commit('set_clients', response.data.data)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getMatterByClientId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/client/${data.client_id}/matter`, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteClient(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/client/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response.data){
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateClient(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/client/${data.id}`, data.client , {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response.data){
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },

};

const mutations = {
  add_client_obj(state, data) {
    state.clients.push(data)
  },
  set_clients(state, data) {
    state.clients = data
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
