// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  employees: [],
  roles: []
};

const getters = {

};

const actions = {
  createEmployee(context, data) {
        return new Promise((resolve, reject) => {
            axios.post('/employee/', data,
            {
              headers: {
                ...authHeaders(localStorage.getItem('access_token'))
              },
            })
            .then(response => {
              
              context.commit('add_employee_obj', response.data.data)
              resolve(response)
            })
            .catch(error => {
              
              reject(error)
            })
        });
  },
  updateEmployee(context, data) {
        
        return new Promise((resolve, reject) => {
            axios.put(`/employee/${data.id}`, data.employee,
            {
              headers: {
                ...authHeaders(localStorage.getItem('access_token'))
              },
            })
            .then(response => {
              
              context.commit('add_employee_obj', response.data.data)
              resolve(response)
            })
            .catch(error => {
              
              reject(error)
            })
        });
  },
  deleteEmployee(context, data) {
        
        return new Promise((resolve, reject) => {
            axios.delete(`/employee/${data.id}`,
            {
              headers: {
                ...authHeaders(localStorage.getItem('access_token'))
              },
            })
            .then(response => {
              context.commit('add_employee_obj', response.data.data)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        });
  },
  getAllEmployee(context) {
    
    return new Promise((resolve, reject) => {
      axios
        .get("/employee/all", {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response){
            context.commit('set_employees', response.data.data)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRoles(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/roles/all", {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          context.commit('set_roles', response.data.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_employee_obj(state, data) {
    state.employees.push(data)
  },
  set_employees(state, data) {
    state.employees = data
  },
  set_roles(state, data) {
    state.roles = data
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
