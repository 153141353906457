<template>
  <v-navigation-drawer v-model="drawer" app permanent left width="194" color="#fff">
    <v-app-bar color="#fff" dense outlined light elevation="0">
      <img class="brand_logo" src="@/assets/aprotime-logo.png">


    </v-app-bar>
    <v-divider></v-divider>
    <v-list v-if="userDetails.roles.has_all_access" subheader flat nav dense>
      <v-list-item-group v-model="selectedItem" active-class="link" color="#CE0000">
        <template v-for="(item) in links">
          <v-subheader v-if="item.header" :key="item.header" class="category_text">
            {{ item.header }}
          </v-subheader>
          <v-list-item v-else :key="item.title" router :to="item.route">
            <v-list-item-icon class="me-2">
              <v-icon size="20" class="nav_icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="nav_text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

      </v-list-item-group>
    </v-list>
    <v-list v-else subheader flat nav dense>
      <v-list-item-group v-model="selectedItem" active-class="link" color="#CE0000">
        <template v-for="(item) in links">
          <v-subheader v-if="item.header" :key="item.header" class="category_text">
            {{ item.header }}
          </v-subheader>
          <v-list-item v-else :key="item.title" router :to="item.route">
            <v-list-item-icon class="me-2">
              <v-icon size="20" class="nav_icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="nav_text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
  
<script>
export default {
  name: 'SideNav',

  data: () => ({
    drawer: true,
    selectedItem: 0,

    links: [
      { header: 'Time Tracking' },
      { title: 'Time Entry', icon: 'mdi-view-dashboard-outline', route: '/auth/time-entry' },
      { title: 'Calendar', icon: 'mdi-list-box-outline', route: '/auth/calendar' },
      { header: 'Invoice & Payments' },
      { title: 'Invoices', icon: 'mdi-receipt-text-outline', route: '/auth/invoices' },
      { header: 'Management' },
      { title: 'Clients', icon: 'mdi-account-outline', route: '/auth/clients' },
      { title: 'Projects', icon: 'mdi-note-text-outline', route: '/auth/projects' },
      { title: 'Employees', icon: 'mdi-account-multiple-outline', route: '/auth/employees' },
      { title: 'Ranks', icon: 'mdi-account-multiple-outline', route: '/auth/ranks' },
      // { title: 'Rates', icon: 'mdi-tag-text-outline', route: '/auth/rates' },
      { title: 'Departments', icon: 'mdi-alpha-d-circle-outline', route: '/auth/departments' },
      { title: 'Offices', icon: 'mdi-office-building-outline', route: '/auth/offices' },
      { title: 'Profit Centre', icon: 'mdi-hand-coin-outline', route: '/auth/profit-centre' },
      // { title: 'Phases', icon: 'mdi-source-branch-check', route: '/auth/phase' },
      // { title: 'Tasks', icon: 'mdi-checkbox-multiple-outline', route: '/auth/task' },
      { title: 'Activities', icon: 'mdi-playlist-check', route: '/auth/activity' },
      // { header: 'Management' },
      { title: 'Settings', icon: 'mdi-cog-outline', route: '/auth/settings' }
    ],
    userLinks: [
      { header: 'Time Tracking' },
      { title: 'Time Entry', icon: 'mdi-view-dashboard-outline', route: '/auth/time-entry' },
      { title: 'Calendar', icon: 'mdi-list-box-outline', route: '/auth/calendar' },
      // { header: 'Management' },
      { title: 'Settings', icon: 'mdi-cog-outline', route: '/auth/settings' }
    ],

    // admins: [
    //   ['Management', 'mdi-account-multiple-outline'],
    //   ['Settings', 'mdi-cog-outline'],
    // ],

  }),

  methods: {

  },
  computed: {
      userDetails() {
          return this.$store.state.Auth.user;
      },
  }
}
</script>
  
<style scoped>
.category_text {
  margin-top: 10px;
}
.logo_link {
  text-decoration: none;
  color: #CE0000;
}
.brand_logo {
  width: 100px;
}
</style>
  