// import router from '@/router';
// import Auth from '@/store/auth'
// import { authHeaders } from "@/store/config";

const state = {
    drawerOpen: false
};

const getters = {
    user: state => state.phases,
    drawerState: (state) => state.drawerOpen

};

const actions = {
    //
};

const mutations = {
    openDrawer(state) {
        // mutate state
        state.drawerOpen = true
    },
    closeDrawer(state, data) {
        // mutate state
        state.drawerOpen = data
    },
};

export default {
    state, getters, actions, mutations, namespaced: true,
}