<template>
  <div class="page">
    <v-icon size="100" color="red" class="icon"
      >mdi-network-strength-off-outline</v-icon
    >
    <h1 class="message-title">Oops! No Internet Connection.</h1>
    <p class="message-body">
      Please check your network settings and try again. If you continue to
      experience issues, contact your internet service provider.
    </p>
    <v-btn color="primary" class="action-btn" @click="retryConnection"
      >Retry</v-btn
    >
  </div>
</template>
  
  <script>
export default {
  name: "OfflinePage",
  methods: {
    retryConnection() {
      location.reload(); // retry by reloading the page
    },
  },
};
</script>
  
  <style scoped>
.page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.icon {
  color: red;
}

.message-title {
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}

.message-body {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.action-btn {
  margin-top: 1rem;
  background: rgb(41, 89, 221) !important;
}
</style>
  