// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
    rates: [],
};

const getters = {
  
};

const actions = {
  createRate(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .post("/employee-rates/", data, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          context.commit('add_rate_obj', response.data.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateRate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/rate/${data.id}`, data.rate, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteRate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/rate/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllRate(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/employee-rates/all", {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response){
            context.commit('set_rates', response.data.data)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRateByEmployeeId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/employee-rates/${data.id}?amount_type=${data.type || 'rate'}`, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response){
            // context.commit('set_rates', response.data.data)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_rate_obj(state, data) {
    state.rates.push(data)
  },
  set_rates(state, data) {
      state.rates = data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
