import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
// import moment from 'moment'
import moment from 'moment-timezone';
import EventBus  from './common/EventBus';
import OfflinePage from './components/OfflinePage.vue'
import { excludedEndpoints } from './constants/excludedEnpoints'

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}`;


let isRefreshAttempted = false; // Flag to track whether refresh has been attempted

axios.interceptors.response.use(
  (response) => response,
  async (error) => {

    const requestUrl = error.config.url;

    // Check if the URL is in the list of excluded endpoints
    const isExcludedEndpoint = excludedEndpoints.some(endpoint => requestUrl.endsWith(endpoint));

    // If the URL matches an excluded endpoint, skip the interceptor logic
    if (isExcludedEndpoint) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    // Check if the error is a 401 Unauthorized and if it's not a retry
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Check if refresh has been attempted
      if (!isRefreshAttempted) {
        isRefreshAttempted = true; // Set the flag to indicate refresh attempt

        try {
          // Attempt to refresh the token using your API endpoint
          // eslint-disable-next-line no-unused-vars
          const refreshToken = localStorage.getItem('refresh_token');
          const response = await axios.post('/org/refresh-token', {
            refreshToken,
          });

          const newAccessToken = response.data.data.token;

          // Update the stored access token
          localStorage.setItem('access_token', newAccessToken);

          // Retry the original request with the new access token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          // Handle refresh token failure (e.g., logout user)
          // console.error('Refresh token failed', refreshError);

           // Show a toast using Vuetify's Snackbar component
           EventBus.dispatch('show-snackbar', {
            color: 'error',
            message: 'Session expired, please log in again.'
          });

          // Logout user using the Vuex store
          // store.dispatch('Auth/logout');

          // Redirect to the login page or perform additional actions
          localStorage.clear()

          router.push('/');

          // Throw an error to reject the original request
          // return 
          return Promise.reject(error);
        }
      }
    } else {
        // General error handling for all other errors
        const errorMessage = error?.response?.data?.error || error.message || "An unexpected error occurred";
        EventBus.dispatch("show-snackbar", {
          color: "error",
          message: errorMessage,
        });
    }

    // If it's not a 401 error or it's a retried request, simply return the error
    // return
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
  if (value) {
    // Get the user's timezone
    const userTimezone = moment.tz.guess();

    // Parse the value as UTC date
    const backendDate = moment.utc(String(value));

    // Format the date according to the user's timezone
    return backendDate.tz(userTimezone).format('MMMM D, YYYY');
  }
});

// Vue.filter('formatDate', function (value) {
//   if (value) {
//     return moment.utc(String(value)).format('MMMM D, YYYY'); // Parse as UTC
//   }
// });

Vue.filter('roundup2D', function (value) {
  if (value) {
    return value.toFixed(2)
  }
});

Vue.filter('roundup1D', function (value) {
  if (value !== undefined && value !== null) {
    return value.toFixed(2);
  } else if (value === 0) {
    return '0.0';
  } else {
    return '';
  }
});

Vue.filter('maskedString', function(value) {
  if (value) {
    return value.slice(-10)
  }
}); 

Vue.filter('amountFormat', num => {
  if (!num) {
    return '0.00';
  }
  const number = (num / 1).toFixed(2).replace(',', '.');
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});



new Vue({
  router,
  store,
  vuetify,
  data() {
    return {
      onlineStatus: navigator.onLine
    };
  },
  computed: {
    isOnline() {
      return this.onlineStatus;
    }
  },
  created() {
    window.addEventListener('online',  () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));
  },
  methods: {
    updateOnlineStatus(status) {
      this.onlineStatus = status;
    }
  },
  render(h) {
    if (this.isOnline) {
      return h(App); // Render the main app if online
    } else {
      return h(OfflinePage); // Render the offline page if offline
    }
  }
}).$mount('#app');

