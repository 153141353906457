import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import VueAxios from "vue-axios";

import App from './app'
import Auth from './auth'
import Client from './client'
import Entry from './entry'
import Phase from './phase'
import Activity from './activity'
import Task from './task'
import Rank from './rank'
import Matter from './matter'
import Employee from './employee'
import Department from './department'
import Office from './office'
import Rate from './rate'
import ProfitCenter from './profitcenter'
import Invoice from './invoice'

Vue.use(Vuex, VueAxios, axios);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth, App, Client, Entry, Phase, Activity, Task, Rank, Matter, 
    Employee, Department, Office, Rate, ProfitCenter, Invoice
  },
  plugins: [createPersistedState()],
});
