<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="form_container d-flex align-center">
          <v-card class="mx-auto" max-width="400" flat>
            <div class="text-center">
              <img src="@/assets/aprotime-logo2.png" />
              <p class="subheading">
                A pro system to take control of your time entry and achive your
                productivity and financial goal with ease.
              </p>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    outlined
                    :rules="emailRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    label="Password"
                    hint="At least 8 characters"
                    class="input-group--focused"
                    outlined
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="#CE0000"
                    class="mb-4"
                    elevation="0"
                    large
                    block
                    dark
                    :loading="loading"
                    @click="login"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <div class="text-center">
              <v-btn text class="mb-4" to="/forgot-password">
                Forgot Password?
              </v-btn>
            </div>
            <hr class="mb-4" />
            <v-card-actions>
              <span>Don’t have an account?</span>
              <v-spacer></v-spacer>
              <v-btn outlined rounded color="#CE0000" to="/register">
                Register
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventBus from "@/common/EventBus";

export default {
  name: "LoginPage",

  components: {},

  data: () => ({
    valid: true,
    show: false,
    loading: false,
    email: "",
    password: "",
    rules: {
      required: (value) => !!value || "Required",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  methods: {
    login() {
      let formstatus = this.$refs.form.validate();
      if (formstatus != true) return;
      this.loading = true;
      this.$store
        .dispatch("Auth/login", {
          email: this.email,
          password: this.password,
        })
        .catch((error) => {
          if(error.response.data.error == 'Organisation is not verified') {
            this.$router.push({
              name: 'Verification',
              params: {
                email: this.email
              }
            })
          }
          EventBus.dispatch("show-snackbar", {
            color: "error",
            message: error.response.data.error,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.form_container {
  height: 100vh;
}

.heading {
  font-size: 1.8rem;
  font-weight: 900;
}

.subheading {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
}
</style>